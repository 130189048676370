const SongListDatabase = {
    "aba_ginoong_maria": "Aba Ginoong Maria",
    "adeste_fideles": "Adeste Fideles",
    "alay_sa_diyos": "Alay sa Diyos",
    "alleluia": "Alleluia",
    "ama_namin": "Ama Namin",
    "ama_namin_v2": "Ama Namin (V2)",
    "amami_ilocano": "Amami",
    "amami_ilocano_v2": "Amami (v2)",
    "ang_pasko_ay_sumapit": "Ang Pasko ay Sumapit",
    "anima_christi": "Anima Christi",
    "babaen_ti_krusmo": "Babaen Ti Krusmo",
    "balang_araw": "Balang Araw",
    "bayan_umawit": "Bayan, Umawit",
    "be_not_afraid": "Be Not Afraid",
    "blessed_be_god": "Blessed Be God",
    "christ_the_lord_is_risen_today": "Christ the Lord Is Risen Today",
    "christify": "Christify",
    "cordero_ti_dios_ilocano": "Cordero Ti Dios",
    "create_in_me": "Create In Me",
    "doxology_to_the_our_father": "Doxology to the Our Father",
    "emmanuel_isang_dalagay_maglilihi": "Emmanuel (Isang Dalaga'y Maglilihi)",
    "gloria_ilocano": "Gloria (Ilocano)",
    "gloria_ilocano_v2": "Gloria (Ilocano)",
    "glory_and_praise_to_our_god": "Glory and Praise To Our God",
    "glory_to_god_light_from_light": "Gloria To God (LFL)",
    "glory_to_god_mass_of_light": "Gloria To God (Mass of Light)",
    "good_christian_men_rejoice": "Good Christian Men Rejoice",
    "great_amen_wyd_95": "Great Amen (WYD ’95)",
    "great_is_our_god": "Great is Our God",
    "hail_holy_queen": "Hail Holy Queen Enthroned Above",
    "handog_namin": "Handog Namin",
    "he_is_exalted": "He is Exalted",
    "here_i_am_lord": "Here I Am, Lord",
    "here_in_this_place": "Here in this place",
    "holy_holy_david_haas": "Holy",
    "hosanna_our_king_is_here": "Hosanna! Our King is Here!",
    "how_lovely_is_your_dwelling_place": "How Lovely is Your Dwelling Place",
    "humayot_ihayag": "Humayo't Ihayag",
    "i_love_the_lord": "I Love The Lord",
    "i_will_sing_forever": "I Will Sing Forever",
    "idatonmi_kenka_apo": "Idatonmi Kenka Apo",
    "iesu_panis_vitae": "Iesu, Panis Vitae",
    "joy_to_the_world": "Joy To The World",
    "kordero_ng_diyos": "Kordero ng Diyos",
    "kyrie_eleison": "Kyrie, Eleison",
    "kyrie_eleison_chorus": "Kyrie Eleison (Chorus)",
    "kyrie_eleison_lfl": "Kyrie, Eleison (LFL)",
    "lamb_of_god": "Lamb of God",
    "lord_we_gather_today": "Lord, We Gather Today",
    "mary_immaculate": "Mary Immaculate",
    "mother_of_christ": "Mother of Christ",
    "naarian_nga_ili": "Naarian Nga Ili",
    "now_we_remain": "Now We Remain",
    "o_come_all_ye_faithful": "O Come All Ye Faithful",
    "o_come_o_come_emmanuel": "O Come O Come, Emmanuel",
    "o_god_beyond_all_praising": "O God Beyond All Praising",
    "o_holy_night": "O Holy Night",
    "o_maria_nalibnoska": "O Maria Nalibnoska",
    "o_sacrament_most_holy": "O Sacrament Most Holy",
    "o_salutaris_hostia": "O Salutaris Hostia",
    "o_santa_lucia": "O, Santa Lucia",
    "on_eagles_wings": "On Eagle's Wings",
    "one_bread_one_body": "One Bread, One Body",
    "one_thing_i_ask": "One Thing I Ask",
    "our_father": "Our Father",
    "paghahandog_ng_sarili": "Paghahandog ng Sarili",
    "pananagutan": "Pananagutan",
    "papuri_sa_diyos": "Papuri sa Diyos",
    "pilgrims_of_hope": "Pilgrims of Hope",
    "praise_to_you_lord_jesus_christ": "Praise to You, Lord Jesus Christ",
    "sa_hapag_ng_panginoon": "Sa Hapag ng Panginoon",
    "sa_krus_mo_at_pagkabuhay": "Sa Krus Mo at Pagkabuhay",
    "salve_regina": "Salve Regina",
    "santo_francisco": "Santo by Manoling Francisco",
    "santo_ilocano": "Santo",
    "santo_ilocano_v2": "Santo (v2)",
    "save_us_o_lord": "Save Us, O Lord",
    "sing_a_new_song": "Sing A New Song",
    "stella_maris": "Stella Maris",
    "ta_kukuam_ti_turay_ilocano": "Ta Kukuam ti Turay, Panakablin, ken Dayaw",
    "tantum_ergo": "Tantum Ergo",
    "tell_the_world_of_his_love": "Tell the World of His Love",
    "the_face_of_god": "The Face Of God",
    "tinapay_at_alak_naming_hatid": "Tinapay at Alak Naming Hatid",
    "tinapay_ng_buhay": "Tinapay ng Buhay",
    "tubig_ng_buhay": "Tubig ng Buhay",
    "umayka_umayka_emmanuel": "Umayka, Umayka Emmanuel",
    "veni_sancte_spiritus": "Veni Sancte Spiritus",
    "veni_sancte_spiritus_taize": "Veni Sancte Spiritus (Taize)",
    "way_of_the_cross": "Way of the Cross",
    "we_remember": "We Remember",
    "we_will_rise": "We Will Rise",
    "what_child_is_this": "What Child Is This?",
    "when_we_eat_this_bread": "When We Eat this Bread",
    "you_are_mine": "You Are Mine",
    "you_shall_be_clothed_with_power": "You shall be clothed with power",
};

export default SongListDatabase;